import React from 'react'
import { graphql } from 'gatsby';
import Layout from "../../../components/Layout";
import "../../../styles/odooServices.scss"
import "../../../styles/services.scss"
import "../../../styles/caseStudy.scss"


import News from '../../../components/News'
import CaseStudeiesTopSection from '../../../components/CaseStudeiesTopSection';
import CaseStudeiesSimplified from '../../../components/CaseStudeiesSimplified';
import CaseStudyDescription from '../../../components/caseStudyDescription';

import headerImage from '../../../images/caseStudies/cakesnbakeslahore.png'

const CakesBakesDAX2012Revamp = ({ data }) => {
  return (
    <div className='CaseStudeies'>
      <Layout pageTitle={"Vsiomate - Dynamics AX 2012 Revamp for Cakes & Bakes"}
      descrip = {"Revitalize your Dynamics AX 2012 system with a comprehensive revamp. Enhance performance, unlock new features, and future-proof your ERP investment. Learn more!"}>
        <CaseStudeiesTopSection
          path='projects'
          headerData={{
            title: "Microsoft Dynamics ERP re-vamp: Streamlined purchase, inventory, production, distribution & sales",
            peragraph: "We combined process re-engineering and solution revamping to modify the Microsoft Dynamics ERP for one of our cake and bake business units after conducting a thorough investigation. In order to satisfy the unique requirements of cakes and bakes, this strategy improved efficiency and optimized operations."
            , image: headerImage
          }}
          data={data} />
        <CaseStudyDescription
          caseStudyData={{
            ITitle: "Introduction",
            Idescription: "The first bakery in Pakistan to receive ISO certification, Cakes & Bakes offers a variety of creative goods that meet both domestic and international standards for quality and safety. Cakes & Bakes provides a wide variety of products to suit every need, catering to every household and family. Everything from breakfast to midday snacks, dairy products to frozen ready-to-eat meals, cakes to desserts, and snacking products are all available.",
            image: headerImage,
            data: [

              {
                Title: "keyReqProblem",
                Point1: "Perfom a detailed analysis to figure out the issues in supply chain processes",
                Point2: "Enhance the Microsoft Dynamics Solution to resolve the issues",
                Point3: "Provide comprehensive reporting for better decision making"
              },
              {
                Title: "Solution",
                Sdescription: "The Visiomate team takes a methodical approach, visiting the site and speaking with important staff to evaluate how well the process is being carried out. After that, a thorough report detailing the problems found and suggested fixes is sent. Once the analysis is over, our implementation team gets to work improving the solution. It is noteworthy that the redesign was completed in a short period of six months.",
                Point1: "Team with best knowledge of the solution was engaged.",
                Point2: "Onsite support while solutino revamp",
                Point3: "Offsite support after go live",
              },
            ],
          }}
          data={data} />
        <CaseStudeiesSimplified
          TextData={{
            title: 'Approach that Achieved Success'
            ,
            Data: [
              {
                peragraph: 'Define Requirements',
                list: [
                  'Clearly outline your business objectives and identify the specific challenges or needs that the software solution should address.',
                  'List down the essential features and functionalities required.'
                ]
              },
              {
                peragraph: 'Demo and Evaluation',
                list: [
                  "Schedule product demonstrations. ",
                  "Evaluate the software's usability, features, and performance. ",
                  "Engage key stakeholders in the evaluation process."
                ]
              },
              {
                peragraph: 'Scalability and Integration',
                list: [
                  "Identify the potential integrations required by the customer.",
                  "Plan the implementation of identified integrations."
                ]
              },
              {
                peragraph: 'UAT & User Feedback',
                list: [
                  "UAT period allows customer to assess the system performance and suitability for the specific business needs.",
                  "Customer’s insights can provide valuable perspectives on usability, functionality mismatch."
                ]
              },
            ]
          }} />
        <News isAbout={true} career={true} />
      </Layout>
    </div >
  )
}

export default CakesBakesDAX2012Revamp
export const query = graphql`
  query cakeAndBackesmages {
    VMLogoBlack: file(relativePath: { eq: "logo_black_LST.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    VMLogoWhite: file(relativePath: { eq: "logo_white_LST.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    newsHeaderImg: file(
      relativePath: { eq: "caseStudies/cakesnbakeslahore.png" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    secondImg: file(
      relativePath: { eq: "caseStudies/cakesnbakeslahore.png" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
